import { merge } from "theme-ui"
import { tailwind } from "@theme-ui/presets";
import parentTheme from "@lekoarts/gatsby-theme-cara/src/gatsby-plugin-theme-ui/index";

const theme = merge(parentTheme, {
  colors: {
    primary: tailwind.colors.green[6],
    secondary: tailwind.colors.blue[6],
    text: tailwind.colors.gray[3],
    heading: tailwind.colors.white,
    background: `#141821`,
    divider: tailwind.colors.gray[8],
    textMuted: tailwind.colors.gray[5],
    icon_brightest: tailwind.colors.green[9],
    icon_darker: tailwind.colors.green[7],
    icon_darkest: tailwind.colors.green[8],
    icon_red: tailwind.colors.green[6],
    icon_blue: tailwind.colors.blue[7],
    icon_orange: tailwind.colors.blue[9],
    icon_yellow: tailwind.colors.blue[8],
    icon_pink: tailwind.colors.green[8],
    icon_purple: tailwind.colors.green[8],
    icon_green: tailwind.colors.green[9],
  },
})

export default theme
